<template>
  <div v-if="lastPage > 1 || (lastPage == 1 && showPerPage)" class="pagination-container">
    <div v-if="currentPage != 1" @click="changePage(currentPage - 1)" class="arrow-wrapper">
      <div class="arrow">
        <img src="@/assets/restyle/images/shared/pagination/pagination_arrow_left.svg" alt="">
      </div>
      <div class="arrow-text">
        Пред. стр.
      </div>
    </div>
    <div v-else class="arrow-hide">
      <div class="arrow">
        <img src="@/assets/restyle/images/shared/pagination/pagination_arrow_left.svg" alt="">
      </div>
      <div class="arrow-text">
        Пред. стр.
      </div>
    </div>
    <div v-if="showJumpPage && (lastPage - currentPage > 6) && (middlePages.at(-1) < lastPage - 1)" class="jump-page-wrapper">
      <span class="jump-page-title">Перейти на страницу</span>
      <SelectSearchSmall
        @changeSearch="pageSearch = $event"
        @selectOption="inputPageHandler"
        :options="pagesArray"
        :search="pageSearch"
        inputType="number"
        :inputMask="'D'.repeat(10)"
      />
    </div>
    <div v-else class="jump-page-wrapper jump-page-wrapper--invisible">
      <span class="jump-page-title">Перейти на страницу</span>
      <SelectSearchSmall
        @changeSearch="pageSearch = $event"
        @selectOption="inputPageHandler"
        :options="pagesArray"
        :search="pageSearch"
        inputType="number"
        :inputMask="'D'.repeat(10)"
      />
    </div>
    <div class="pagination-middle-wrapper">
      <div v-if="showSkipPage" class="skip-page-wrapper skip-page-wrapper--left">
        <div class="skip-page-block" @click="changePage(currentPage - 10)" v-if="(currentPage - 10) >= 1">
          <div class="skip-page-arrows-wrapper">
            <img class="skip-page-arrows-image" src="@/assets/restyle/images/shared/pagination/pagination_arrow_left.svg" alt=""/>
            <img class="skip-page-arrows-image" src="@/assets/restyle/images/shared/pagination/pagination_arrow_left.svg" alt=""/>
            <img class="skip-page-arrows-image" src="@/assets/restyle/images/shared/pagination/pagination_arrow_left.svg" alt=""/>
          </div>
          <div class="skip-page-title">по 10</div>
        </div>
        <div class="skip-page-block skip-page-block--invisible" v-else>
          <div class="skip-page-arrows-wrapper">
            <img class="skip-page-arrows-image" src="@/assets/restyle/images/shared/pagination/pagination_arrow_left.svg" alt=""/>
            <img class="skip-page-arrows-image" src="@/assets/restyle/images/shared/pagination/pagination_arrow_left.svg" alt=""/>
            <img class="skip-page-arrows-image" src="@/assets/restyle/images/shared/pagination/pagination_arrow_left.svg" alt=""/>
          </div>
          <div class="skip-page-title">по 10</div>
        </div>
        <div class="skip-page-block" @click="changePage(currentPage - 5)" v-if="(currentPage - 5) >= 1">
          <div class="skip-page-arrows-wrapper">
            <img class="skip-page-arrows-image" src="@/assets/restyle/images/shared/pagination/pagination_arrow_left.svg" alt=""/>
            <img class="skip-page-arrows-image" src="@/assets/restyle/images/shared/pagination/pagination_arrow_left.svg" alt=""/>
          </div>
          <div class="skip-page-title">по 5</div>
        </div>
        <div class="skip-page-block skip-page-block--invisible" v-else>
          <div class="skip-page-arrows-wrapper">
            <img class="skip-page-arrows-image" src="@/assets/restyle/images/shared/pagination/pagination_arrow_left.svg" alt=""/>
            <img class="skip-page-arrows-image" src="@/assets/restyle/images/shared/pagination/pagination_arrow_left.svg" alt=""/>
          </div>
          <div class="skip-page-title">по 5</div>
        </div>
      </div>
      <div
        v-if="!(lastPage == 1 && showPerPage)"
        @click="changePage(1)"
        :class="{
          'active-item': currentPage === 1,
          'active-item--blue': blueTheme && currentPage === 1,
          'page-item-wrapper--blue': blueTheme,
          }"
        class="page-item-wrapper"
      >
        <div class="page-item">
          1
        </div>
      </div>
      <div v-if="currentPage > 6 && middlePages[0] > 2" class="dots">
        ...
      </div>
      <template v-for="page in middlePages">
        <div
          @click="changePage(page)"
          :class="{
            'active-item': currentPage === page,
            'active-item--blue': blueTheme && currentPage === page,
            'page-item-wrapper--blue': blueTheme,
          }"
          class="page-item-wrapper"
          :key="'page-' + page"
        >
          <div class="page-item">
            {{ page }}
          </div>
        </div>
      </template>
      <div v-if="(lastPage - currentPage > 6) && (middlePages.at(-1) < lastPage - 1)" class="dots">
        <div>
          ...
        </div>
      </div>
      <div
        v-if="!(lastPage == 1 && showPerPage)"
        @click="changePage(lastPage)"
        :class="{
          'active-item': currentPage === lastPage,
          'active-item--blue': blueTheme && currentPage === lastPage,
          'page-item-wrapper--blue': blueTheme,
        }"
        class="page-item-wrapper"
      >
        <div class="page-item">
          {{ lastPage }}
        </div>
      </div>
      <div v-if="showSkipPage" class="skip-page-wrapper skip-page-wrapper--right">
        <div class="skip-page-block" @click="changePage(currentPage + 5)" v-if="(currentPage + 5) <= lastPage">
          <div class="skip-page-title">по 5</div>
          <div class="skip-page-arrows-wrapper">
            <img class="skip-page-arrows-image" src="@/assets/restyle/images/shared/pagination/pagination_arrow_right.svg" alt=""/>
            <img class="skip-page-arrows-image" src="@/assets/restyle/images/shared/pagination/pagination_arrow_right.svg" alt=""/>
          </div>
        </div>
        <div class="skip-page-block skip-page-block--invisible" v-else>
          <div class="skip-page-title">по 5</div>
          <div class="skip-page-arrows-wrapper">
            <img class="skip-page-arrows-image" src="@/assets/restyle/images/shared/pagination/pagination_arrow_right.svg" alt=""/>
            <img class="skip-page-arrows-image" src="@/assets/restyle/images/shared/pagination/pagination_arrow_right.svg" alt=""/>
          </div>
        </div>
        <div class="skip-page-block" @click="changePage(currentPage + 10)" v-if="(currentPage + 10) <= lastPage">
          <div class="skip-page-title">по 10</div>
          <div class="skip-page-arrows-wrapper">
            <img class="skip-page-arrows-image" src="@/assets/restyle/images/shared/pagination/pagination_arrow_right.svg" alt=""/>
            <img class="skip-page-arrows-image" src="@/assets/restyle/images/shared/pagination/pagination_arrow_right.svg" alt=""/>
            <img class="skip-page-arrows-image" src="@/assets/restyle/images/shared/pagination/pagination_arrow_right.svg" alt=""/>
          </div>
        </div>
        <div class="skip-page-block skip-page-block--invisible" v-else>
          <div class="skip-page-title">по 10</div>
          <div class="skip-page-arrows-wrapper">
            <img class="skip-page-arrows-image" src="@/assets/restyle/images/shared/pagination/pagination_arrow_right.svg" alt=""/>
            <img class="skip-page-arrows-image" src="@/assets/restyle/images/shared/pagination/pagination_arrow_right.svg" alt=""/>
            <img class="skip-page-arrows-image" src="@/assets/restyle/images/shared/pagination/pagination_arrow_right.svg" alt=""/>
          </div>
        </div>
      </div>
    </div>
    <div class="show-elements-wrapper" v-if="showPerPage">
      <div class="show-elements-title">Показывать по</div>
      <SelectSmallNumbers
        @select="changePerPage"
        :options="perPageOptions"
        :selectedOption="perPageSelected"
      />
      <div class="show-elements-title">шт. на странице</div>
    </div>
    <div class="show-elements-wrapper show-elements-wrapper--invisible" v-else>
      <div class="show-elements-title">Показывать по</div>
      <SelectSmallNumbers
        @select="changePerPage"
        :options="perPageOptions"
        :selectedOption="perPageSelected"
      />
      <div class="show-elements-title">шт. на странице</div>
    </div>
    <div v-if="lastPage != currentPage" @click="changePage(currentPage + 1)" class="arrow-wrapper">
      <div class="arrow-text">
        След. стр.
      </div>
      <div class="arrow">
        <img src="@/assets/restyle/images/shared/pagination/pagination_arrow_right.svg" alt="">
      </div>
    </div>
    <div v-else class="arrow-hide">
      <div class="arrow-text">
        След. стр.
      </div>
      <div class="arrow">
        <img src="@/assets/restyle/images/shared/pagination/pagination_arrow_right.svg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
  import SelectSearchSmall from '@/components/restyle/primitives/select/SelectSearchSmall.vue';
  import SelectSmallNumbers from '@/components/restyle/primitives/select/SelectSmallNumbers.vue';

  export default {
    name: 'PaginationRestyle',
    components: {
      SelectSearchSmall,
      SelectSmallNumbers,
    },
    props: {
      currentPage: {
        type: Number,
        default: 1
      },
      lastPage: {
        type: Number,
        default: 1
      },
      showSkipPage: {
        type: Boolean,
        default: false,
      },
      showJumpPage: {
        type: Boolean,
        default: false,
      },
      showPerPage: {
        type: Boolean,
        default: false,
      },
      perPageOptions: {
        type: Array,
        default: () => [6, 12, 24, 48, 96, 192],
      },
      perPageSelected: {
        type: Number,
        default: 12,
      },
      disableRouteChange: {
        type: Boolean,
        default: false,
      },
      blueTheme: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        pageSearch: '',
      }
    },
    computed: {
      middlePages() {
        const pages = [];
        let startPage = this.currentPage - 4;
        let endPage = this.currentPage + 4;

        if (this.currentPage <= 6) {
          startPage = 2;
          endPage = Math.min(10, this.lastPage - 1);
        } else if (this.lastPage - this.currentPage <= 6) {
          startPage = this.lastPage - 9;
          endPage = this.lastPage - 1;
        }

        if (startPage < 2) {
          startPage = 2;
        }

        if (endPage >= this.lastPage) {
          endPage = this.lastPage - 1;
        }

        for (let page = startPage; page <= endPage; page++) {
          pages.push(page);
        }
        return pages;
      },
      pagesArray() {
        let pagesArr = [];
        for (let i = 1; i <= this.lastPage; i++) {
          pagesArr.push({ name: String(i) });
        }
        return pagesArr;
      }
    },
    methods: {
      changePage(page) {
        if (this.currentPage != page) {
          if (this.disableRouteChange) {
            this.$emit('changePage', Number(page));
            return;
          }
          this.$router.push({ query: { ...this.$route.query, page } });
          this.$emit('changePage');
        }
      },
      inputPageHandler(page) {
        if (Number(page) <= this.lastPage) {
          this.changePage(page);
        }
      },
      changePerPage(event) {
        if (this.perPageSelected !== event) {
          this.$emit('perPageSelect', event);
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .active-item {
    background: #44FF8F;
    &--blue {
      background: #466ABF;
      color: #FFF;
    }
  }

  .pagination-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 16px;
    line-height: 22px;
    color: #1D1871;
  }

  .arrow-wrapper {
    display: flex;
    cursor: pointer;
  }

  .pagination-middle-wrapper {
    display: flex;
    gap: 5px;
    position: relative;
    align-items: center;
  }

  .page-item-wrapper {
    min-width: 30px;
    height: 30px;
    padding: 2px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    &:active {
      background: #44FF8F;
    }
    &--blue {
      &:active {
        background: #466ABF;
        color: #FFF;
      }
    }
  }

  .dots {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;

    &--jump-page {
      width: 68px;
    }
  }

  .arrow,.arrow-text {
    margin-right: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .arrow-hide {
    display: flex;
    cursor: default;
    opacity: 0;
  }

  .jump-page-wrapper {
    margin: 0 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    &--invisible {
      visibility: hidden;
    }
  }

  .jump-page-title {
    font-family: "Noto Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #4F4B85;
  }

  .skip-page-wrapper {
    position: absolute;
    display: flex;
    gap: 10px;
    &--left {
      left: -100px;
    }
    &--right {
      right: -107px;
    }
  }

  .skip-page-block {
    display: flex;
    gap: 5px;
    font-family: "Noto Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #4F4B85;
    cursor: pointer;
    &--invisible {
      visibility: hidden;
    }
  }

  .skip-page-arrows-image {
    width: 4px;
    height: 7px;
  }

  .show-elements-title, .jump-page-title {
    font-family: "Noto Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #4F4B85;
  }
  .show-elements-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    &--invisible {
      visibility: hidden;
    }
  }

</style>