<template>
   <RestyleModal
      @close="setIsVisibleModalReportWorkDrivers(false)"
      @cancel="setIsVisibleModalReportWorkDrivers(false)"
      @save="BTNDownload"
      :sendDisabled="isDisabledBtn"
   >
   <template slot="header">
      Отчёт по работе водителей
   </template>
   <div>
      <div style="min-height: 350px">
         <div class="mb-4">
            <DateRangePicker
               @dateChange="changeFilter('dates', $event)"
               @dayclick="handleDayClick"
               :dates="currentDates"
               :dateSelectBoxStyle="dateSelectBoxStyle"
               :minDate="minDate"
               :maxDate="maxDate"
               :canClearDate="true"
               class="date-filter"
            />
         </div>
         <div class="mb-4">
            <SearchFilterMultiSelect
               :allSelectOptions="computedUserOrganisations"
               :inputText="searchUserOrganisation"
               @inputChange="searchUserOrganisation = $event"
               @selectedOptionsChange="selectedUserOrganisation = $event"
               :selectedOptions="selectedUserOrganisation"
               placeholder="Организации"
               :wordForms="['организация', 'организации', 'организаций']"
               :wordFormsSelected="['Выбрана', 'Выбрано', 'Выбрано']"
               class="text-left"
               style="width: 300px"
            />
         </div>
         <div style="display: flex; align-items: center; cursor: pointer; margin-left: 10px;"
         @click.self="skipNavigation = !skipNavigation">
            <CheckboxSquare
               style="margin-right: 10px"
               v-model="skipNavigation"
            />
            Без навигации
         </div>
      </div>
   </div>
   <template slot="confirmButtonText"> Скачать </template>
   </RestyleModal>
</template>

<script>
import DateRangePicker from "@/components/restyle/calendar/DateRangePicker.vue";
import CheckboxSquareMultiselectFilter from '@/components/restyle/primitives/checkboxes/CheckboxSquareMultiselectFilter.vue';
import SearchFilterMultiSelect from "@/components/restyle/primitives/filters/SearchFilterMultiSelect.vue";
import { transformOrgsIntoOrgTree } from "@/_helpers/functions/functions";
import { mapGetters, mapMutations } from "vuex";
import { request } from "@/_helpers";
import CheckboxSquare from '@/components/restyle/primitives/checkboxes/checkbox_square.vue';

export default {

   components: {
      DateRangePicker,
      CheckboxSquareMultiselectFilter,
      SearchFilterMultiSelect,
      CheckboxSquare
   },
   data() {
      return {
         dateSelectBoxStyle: { width: '270px', height: 'unset' },
         minDate: null,
         maxDate: null,
         currentDates: { start: null, end: null },
         firstClickedDate: null,
         searchUserOrganisation: "",
         selectedUserOrganisation: [],
         isDisabledBTN: false,
         skipNavigation: false,
      }
   },
   created() {
      const today = new Date();
      const formattedToday = this.formatDate(today);
      this.$set(this.currentDates, 'start', formattedToday);
      this.$set(this.currentDates, 'end', formattedToday);
      this.minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 365);
      this.maxDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 365);
   },
   methods: {
      ...mapMutations({
         setIsWaitAlertAfterRequest: "user_notifications/SET_IS_WAIT_ALERT_AFTER_REQUEST",
         setIsVisibleModalReportWorkDrivers: "online_monitoring/set_is_visible_modal_report_work_drivers"
      }),
      formatDate(date) {
         const yyyy = date.getFullYear();
         const mm = String(date.getMonth() + 1).padStart(2, '0');
         const dd = String(date.getDate()).padStart(2, '0');
         return `${dd}-${mm}-${yyyy}`;
      },
      handleDayClick(event) {
         if (!event.isDisabled) {
         const clickedDate = new Date(event.id);
         const formattedClickedDate = this.formatDate(clickedDate);
         if (!this.firstClickedDate) {
            this.firstClickedDate = clickedDate;
            this.currentDates = { start: formattedClickedDate, end: formattedClickedDate };
         } else {
               let start = this.firstClickedDate;
               let end = clickedDate;
               if (start > end) {
                  [start, end] = [end, start];
               }
               const diffDays = (end - start) / (1000 * 60 * 60 * 24);
               if (diffDays <= 365) {
                  this.currentDates = { start: this.formatDate(start), end: this.formatDate(end) };
               } else {
                  alert('Период не должен превышать 1 год.');
               }
               this.firstClickedDate = null;
            }
         }
      },
      changeFilter(nameFilter, event) {
         if (nameFilter === 'dates') {
            this.currentDates = event;
         }
      },
      BTNDownload() {
         this.isDisabledBTN = true;
         const organisationIDS = this.selectedUserOrganisation.map(item => item.id);
         request(`${process.env.VUE_APP_WN}/fact/waybill/report`, "post", {
            begin_date: this.currentDates.start.split('-').reverse().join('-'),
            end_date: this.currentDates.end.split('-').reverse().join('-'),
            organisation_ids: organisationIDS,
            skip_navigation: this.skipNavigation,
         })
         .then((response) => {
            if (response.success) {
               this.$store.dispatch('alert/alerts', { message: `${response.message}`, type: 'success' });    
               this.setIsWaitAlertAfterRequest(true);   
            }
         }).catch((error) => {
            this.$store.dispatch('alert/alerts', { message: `${error.message}`, type: 'danger' });
         }).finally(() => {
            this.isDisabledBTN = false;
            this.setIsWaitAlertAfterRequest(false); 
         })
      },
      isSelected(option) {
         return this.selectedOptions.some(selectedOption => selectedOption.name === option.name && selectedOption.type === option.type);
      },
      chooseAll() {
         return this.filteredOptions.length > 0 && this.filteredOptions.every(option => this.isSelected(option));
      },
      toggleAllFilteredOptions() {
         if (this.chooseAll) {
         this.$emit('selectedOptionsChange', []);
         } else {
         this.$emit('selectedOptionsChange', this.filteredOptions);
         }
      },
      computedPlaceholder() {
         if (this.selectedOptions.length > 0) {
            return `${getWordForm(this.selectedOptions.length, this.wordFormsSelected)}`
            + ` ${this.selectedOptions.length} `
            + `${getWordForm(this.selectedOptions.length, this.wordForms)}`;
         } else {
            return this.placeholder;
         }
      },
   },
   computed: {
      ...mapGetters({
         userOrganisations: 'user/userOrganisations',
      }),
      isDisabledBtn() {
         return !this.selectedUserOrganisation.length || !this.currentDates?.start || !this.currentDates?.end || this.isDisabledBTN;
      },
      computedUserOrganisations () {
         let result = transformOrgsIntoOrgTree(this.userOrganisations)
         result = result.reduce((acc, item) => {
         acc.push(item)

         if (item.children?.length > 0) {
            for (const iterator of item.children) {
               acc.push({...iterator, itsChild: true})
            }
         }

         return acc
         }, [])
         
         return result
      }
      }
};
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
   .date-filter {
      & .date-value {
         color: $color-button-text !important;
      }
   }
</style>