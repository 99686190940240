const emptyFilters = {
  platform_srp_id: '',
  platform_address: '',
  organisation_ids: [],
  platform_type_id: null,
  container_type: null,
  container_schedule: null,
  confirmations: [],
  violations: [],
  route_id: '',
  route: '',
  vehicle_plan: null,
  vehicle_fact: null,
  lots: [],
  is_photo_verified_option: null,
  export_types: [],
  sort: 'asc',
  states: [],
  serve_driver_first: false,
};

export const online_list_table_filters = {
  namespaced: true,
  state: {
    filters: emptyFilters,
  },
  getters: {
    filters: state => state.filters,
  },
  actions: {
    changeFilters({ commit }, payload) {
      commit('changeFilters', payload);
    },
    clearFilters({ commit }) {
      commit('clearFilters');
    },
  },
  mutations: {
    changeFilters(state, payload) {
      state.filters = payload;
    },
    clearFilters(state) {
      state.filters = emptyFilters;
    },
  }
}