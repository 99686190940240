<template>
  <div class="detail-wrapper scrollbar scrollbar--secondary" ref="detailWrapper">
    <div class="detail-chat-intersection" ref="refDetailChatIntersection"></div>
    <div 
      v-for="detailChat of dataChat" :key="detailChat.id"
      class="detail-chat"
      :class="{'detail-question': detailChat.user.id != 0, 'detail-answer': detailChat.user.id === 0, }"
      v-html="detailChat.content"
    > 
    </div>
  </div>
</template>

<script>
import { request } from "@/_helpers";

export default {
  name: 'messenger-detail-chat',
  props: {
    getDetailChat: {
      required: true,
      type: Object,
      default: () => ({}),
    }
  },
  components: {

  },
  data() {
    return {
      current_page: 1,
      last_page: this.getDetailChat.last_page,
    }
  },
  mounted() {
    this.scrollToBottom();
    this.initialIntersection();
  },
  computed: {
    dataChat() {
      return this.getDetailChat.data?.reverse();
    }
  },
  methods: {
    scrollToBottom() {
      const container = this.$refs.detailWrapper;
      container.scrollTop = container.scrollHeight;
    },
    initialIntersection() {
      let options = {
        root: this.$refs.detailWrapper,
        rootMargin: '400px 0px 0px 400px',
        threshold: 0,
      }

      const emitNewFetchDetail = (event) => {
        if (event[0].isIntersecting && this.last_page > 1 && this.current_page < this.last_page) {
          this.current_page += 1;
          this.fetchMessanger();
        }
      }
      
      let observer = new IntersectionObserver(emitNewFetchDetail, options);

      observer.observe(this.$refs.refDetailChatIntersection);
    },
    fetchMessanger() {
      request(`${process.env.VUE_APP_WN}/chat/${this.getDetailChat.id}/messages?page=${this.current_page}`, 'GET').then(res => {
        if (res.success) {
          this.$store.dispatch('messenger/setDetailChat', { ...res, id: this.getDetailChat.id, data: [...this.getDetailChat.data.reverse(), ...res.data] });
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-wrapper {
  padding: 10px;
  padding-top: 25px;
  padding-bottom: 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #1d838d;
  // color: $color-simple-text;
  color: $color-page-text;
}

.detail-chat {
  margin-bottom: 10px;
  padding: 10px;
  // border: 1px solid $color-page-text;
  border-radius: 10px;
}

.detail-question {
  margin-left: 40px;
  background-color: #CAFFEC;
}

.detail-answer {
  margin-right: 40px;
  background-color: #fff;
}
</style>