var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reference-block scrollbar scrollbar--secondary"},[_c('div',{staticClass:"reference-block-link",attrs:{"id":"contract-block"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'contract-block-services' }}},[_c('div',{staticClass:"link-flex"},[_c('div',{staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('contract-block-services'))+" ")]),(_vm.isActiveHotSpot('contract-block-services')?.id)?_c('div',{staticClass:"hotspot",staticStyle:{"margin-left":"-20px"},on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('contract-block-services'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1),_c('div',{staticClass:"reference-block-link",attrs:{"id":"contract-block-list-counterparties"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'contract-block-list-counterparties' }}},[_c('div',{staticClass:"link-flex"},[_c('div',{staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('contract-block-list-counterparties'))+" ")]),(_vm.isActiveHotSpot('contract-block-list-counterparties')?.id)?_c('div',{staticClass:"hotspot",staticStyle:{"margin-left":"-20px"},on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('contract-block-list-counterparties'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1),_c('div',{staticClass:"reference-block-link",attrs:{"id":"contract-block-list-contracts"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'contract-block-list-contracts' }}},[_c('div',{staticClass:"link-flex"},[_c('div',{staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('contract-block-list-contracts'))+" ")]),(_vm.isActiveHotSpot('contract-block-list-contracts')?.id)?_c('div',{staticClass:"hotspot",staticStyle:{"margin-left":"-20px"},on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('contract-block-list-contracts'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1),_c('div',{staticClass:"reference-block-link",attrs:{"id":"contract-block-waste-generator-categories"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'contract-block-waste-generator-categories' }}},[_c('div',{staticClass:"link-flex"},[_c('div',{staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('contract-block-waste-generator-categories'))+" ")]),(_vm.isActiveHotSpot('contract-block-waste-generator-categories')?.id)?_c('div',{staticClass:"hotspot",staticStyle:{"margin-left":"-20px"},on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('contract-block-waste-generator-categories'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1),_c('div',{staticClass:"reference-block-link",attrs:{"id":"contract-block-templates"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'contract-block-templates' }}},[_c('div',{staticClass:"link-flex"},[_c('div',{staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('contract-block-templates'))+" ")]),(_vm.isActiveHotSpot('contract-block-templates')?.id)?_c('div',{staticClass:"hotspot",staticStyle:{"margin-left":"-20px"},on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('contract-block-templates'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1),_c('div',{staticClass:"reference-block-link",attrs:{"id":"contract-block-list-objects"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'contract-block-list-objects' }}},[_c('div',{staticClass:"link-flex"},[_c('div',{staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('contract-block-list-objects'))+" ")]),(_vm.isActiveHotSpot('contract-block-list-objects')?.id)?_c('div',{staticClass:"hotspot",staticStyle:{"margin-left":"-20px"},on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('contract-block-list-objects'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }