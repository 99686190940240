<template>
  <div class="wrapper-list scrollbar scrollbar--secondary">
    <div
      v-for="chat of getChats"
      :key="chat.id"
      @click="openDetailChat(chat)"
      class="item-chat"
      v-html="listHtml(chat)"
    >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { request } from "@/_helpers";

export default {
  name: 'messenger-list-chat',
  components: {

  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      getChats: 'messenger/getChats',
    }),
  },
  methods: {
    openDetailChat(currentChat) {
        this.$store.dispatch('messenger/setLoadingDetailChat', true);
        
        request(`${process.env.VUE_APP_WN}/chat/${currentChat.id}/messages`, 'GET')
          .then(res => {
            this.$store.dispatch('messenger/setDetailChat', { ...res, id: currentChat.id });
          })
          .finally(()=> {
            this.$store.dispatch('messenger/setLoadingDetailChat', false);
          })
    },
    listHtml(myHtml) {
      const viewText = !myHtml.last_message? 'В этом чате нет переписки, нажмите чтобы начать': `${myHtml.last_message?.content.substring(0, 106)} ${myHtml.last_message?.content.length > 106? '...': ''}`;
      const result = viewText + `<div class="item-chat-delimitter"></div>`
      return result
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper-list {
  padding-top: 25px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  background-color: #1d838d;
  height: 100%;
}

.item-chat {
  padding: 5px 10px;
  min-height: 80px;
  color: #fff;
  font-size: 14px;
  border-bottom: 1px solid #D1D9E6;
  cursor: pointer;
}
</style>