const defaultState = () => ({
  isUpdateIncidents: false,
})

export const rework_online_monitoring_incidents = {
  namespaced: true,
  state: defaultState(),
  getters: {
    getIsUpdateIncidents: state => state.isUpdateIncidents,
  },
  actions: {
    setIsUpdateIncidents({ commit }, payload) {
      commit('setIsUpdateIncidents', payload);
    },
    resetState({ commit }) {
      commit('resetState');
    },
  },
  mutations: {
    setIsUpdateIncidents(state, payload) {
      state.isUpdateIncidents = payload;
    },
    resetState(state) {
      Object.assign(state, defaultState());
    },
  }
}