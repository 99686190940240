<template>
   <RestyleModal
      @close="setIsVisibleModalReporNoNExportAct(false)"
      @cancel="setIsVisibleModalReporNoNExportAct(false)"
      @save="requestNonExportAct"
      :sendDisabled="isDisabledBtn"
      :customWidth="'700px'"
   >
   <template #header>
      Отчёт акт по невывозам
      <img v-if="isSend" src="@/assets/loading_mini_green_no_background.gif" alt="" class="is-send-logo">
   </template>
   <div>
      <div style="min-height: 450px">
         <div class="mb-4">
            <vDatePicker
               v-model="date"
               :model-config="{type: 'string', mask: 'YYYY-MM-DD'}"
               :max-date="maxDate"
               :is-required="true"
            >
               <template #default="{ inputValue, inputEvents }">
                  <input
                  class="date-non-export-act"
                  :value="inputValue"
                  v-on="inputEvents"
                  />
               </template>
            </vDatePicker>
         </div>
         <div class="mb-4">
            <SearchFilterSelect
               :inputText="organisationSearch"
               :placeholder="userOrganisation?.name || 'Организация'"
               :showTooltip="true"
               :tooltipDelay="500"
               :options="computedUserOrganisations"
               :selectedOption="userOrganisation"
               @selectChange="changeUserOrg($event)"
               :hiddenCrossVar="false"
               style="width: 280px"
            />
         </div>
         <SearchFilterMultiSelect
            v-if="lots"
            :allSelectOptions="lots"
            :inputText="searchLot"
            @inputChange="searchLot = $event"
            @selectedOptionsChange="selectedLot = $event"
            :selectedOptions="selectedLot"
            placeholder="Лоты"
            :wordForms="['лот', 'лоты', 'лотов']"
            :wordFormsSelected="['Выбран', 'Выбрано', 'Выбрано']"
            class="text-left"
            style="width: 280px"
         />
      </div>
   </div>
   <template #confirmButtonText> Скачать </template>
   </RestyleModal>
</template>

<script>

import CheckboxSquareMultiselectFilter from '@/components/restyle/primitives/checkboxes/CheckboxSquareMultiselectFilter.vue';
import { transformOrgsIntoOrgTree } from "@/_helpers/functions/functions";
import { mapGetters, mapMutations } from "vuex";
import { request } from "@/_helpers";
import SearchFilterSelect from '@/components/restyle/primitives/filters/SearchFilterSelect.vue';
import SearchFilterMultiSelect from "@/components/restyle/primitives/filters/SearchFilterMultiSelect.vue";

export default {

   components: {
      CheckboxSquareMultiselectFilter,
      SearchFilterSelect,
      SearchFilterMultiSelect
   },
   data() {
      return {
         minDate: new Date(new Date().setDate(new Date().getDate() - 365)),
         maxDate: new Date(),
         isSend: false,
         organisationSearch: '',
         searchLot: '',
         userOrganisation: null,
         isGetLotsFinish: false,
         // Filters
         date: this.formatDate(new Date()),
         lots: null,
         selectedLot: [],
      }
   },
   methods: {
      ...mapMutations({
         setIsVisibleModalReporNoNExportAct: "online_monitoring/set_is_visible_modal_report_non_export_act",
      }),
      changeUserOrg(event) {
         this.userOrganisation = event; 
         this.selectedLot = [];
         this.getLots();
      },
      changeLot(event) {
         this.lots = event;
      },
      requestNonExportAct() {
         const AUTH = localStorage.getItem('auth_token');
         const queryParams = new URLSearchParams({
            date: this.date,
         });
         if (this.selectedLot) {
            this.selectedLot.forEach(lot => {
               queryParams.append('lots[]=', lot.name);
            });
         }
         const organisationID = this.userOrganisation.id;
         const downloadUrl = `${process.env.VUE_APP_WN}/fact/failure/${organisationID}/get_act?${queryParams}&api_token=${AUTH}`;
         
         window.location.href = downloadUrl;
      },
      formatDate(date) {
         const yyyy = date.getFullYear();
         const mm = String(date.getMonth() + 1).padStart(2, '0');
         const dd = String(date.getDate()).padStart(2, '0');
         return `${yyyy}-${mm}-${dd}`;
      },
      getLots() {
         this.isSend = true;
         request(`${process.env.VUE_APP_WN}/fact/platform/lots?organisation_id=${this.userOrganisation.id}`, 'GET')
            .then(res => {
               if (res.success) {
                  this.lots = res.data.map((e, index) => ({
                     id: index,
                     name: e
                  }));
               }
            }).finally(() => {
               this.isGetLotsFinish = true;
               this.isSend = false;
            });
      },
   },
   computed: {
      ...mapGetters({
         userOrganisations: 'user/userOrganisations',
      }),
      isDisabledBtn() {
         return !this.userOrganisation || !this.date || this.isSend || !this.isGetLotsFinish
      },
      computedUserOrganisations () {
         let result = transformOrgsIntoOrgTree(this.userOrganisations)
         result = result.reduce((acc, item) => {
         acc.push(item)

         if (item.children?.length > 0) {
            for (const iterator of item.children) {
               acc.push({...iterator, itsChild: true})
            }
         }

         return acc
         }, [])
         
         return result
      }
      }
};
</script>

<style lang="scss" scoped>

</style>

<style lang="scss" scoped>
.date-non-export-act {
  padding: 10px 12px 10px 12px;
  color: $color-page-text;
  border: 1px solid $color-link;
  border-radius: 5px;
  font-size: 18px;
  width: 300px;
}

.is-send-logo {
   height: 35px;
}
</style>