var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reference-block"},[_c('div',{staticClass:"reference-block-link",attrs:{"id":"report-work-drivers"}},[_c('div',{staticClass:"link-flex",on:{"click":function($event){return _vm.setIsVisibleModalReportWorkDrivers(true)}}},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('report-work-drivers'))+" "),(_vm.isActiveHotSpot('report-work-drivers')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
          hoverEvent: $event,
          tooltipText: _vm.currentTooltipForHotSpot('report-work-drivers'),
        })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])]),(_vm.userCan('om_report'))?_c('div',{staticClass:"reference-block-link"},[_c('div',{staticClass:"link-flex",on:{"click":function($event){return _vm.setIsVisibleModalOMReport(true)}}},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('report-on-sz'))+" "),(_vm.isActiveHotSpot('report-on-sz')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
          hoverEvent: $event,
          tooltipText: _vm.currentTooltipForHotSpot('report-on-sz'),
        })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"reference-block-link",attrs:{"id":"report-delay"}},[_c('div',{staticClass:"link-flex",on:{"click":function($event){return _vm.setIsVisibleModalReportDelay(true)}}},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('report_delay'))+" "),(_vm.isActiveHotSpot('report_delay')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
          hoverEvent: $event,
          tooltipText: _vm.currentTooltipForHotSpot('report_delay'),
        })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])]),_c('div',{staticClass:"reference-block-link"},[_c('div',{staticClass:"link-flex",on:{"click":function($event){return _vm.setIsVisibleModalReportTransporterValue(true)}}},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('report-transporter-value'))+" "),(_vm.isActiveHotSpot('report-transporter-value')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
          hoverEvent: $event,
          tooltipText: _vm.currentTooltipForHotSpot('report-transporter-value'),
        })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])]),(_vm.userCan('show_failure_act'))?_c('div',{staticClass:"reference-block-link",attrs:{"id":"non-export-act"}},[_c('div',{staticClass:"link-flex",on:{"click":function($event){return _vm.setIsVisibleModalReporNoNExportAct(true)}}},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('non-export-act'))+" "),(_vm.isActiveHotSpot('non-export-act')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
          hoverEvent: $event,
          tooltipText: _vm.currentTooltipForHotSpot('non-export-act'),
        })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }