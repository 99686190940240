<template>
  <!--- Sidemenu -->
  <div id="sidebar-menu" class="active">
    <!-- Left Menu Start -->
    <ul class="metismenu in" id="side-menu">
      <li class="menu-title">Навигация</li>
      <NavigationItem :to="'home'" :icon="'ti-home'">Главная</NavigationItem>

      <NavigationGroup v-if="userCan('admin') || userCan('superadmin')" :section="'admin'" :title="'Администрирование'" :icon="'mdi mdi-settings'">
        <NavigationItem :to="'role_all'" v-if="userCan('role_read_all')">Роли</NavigationItem>
        <NavigationItem to="roles_srp" v-if="userCan('role_read_all')">Роли SRP</NavigationItem>
        <NavigationItem v-if="userCan('organisation_read_all')" :to="'organisations'">Организации</NavigationItem>
        <NavigationItem :to="'user_all'" v-if="userCan('user_read_all')">Пользователи</NavigationItem>
      </NavigationGroup>

      <NavigationGroup :title="'Справочники'" :section="'reference'" :icon="'mdi mdi-bookmark-outline'" v-if="userCan('container_type_read_all') || userCan('container_platform_type_read_all') || userCan('failure_reason_read_all') || userCan('breakdowns_type_read_all') || userCan('payment_category_name_read_all')">
        <NavigationItem :to="'container_type'" v-if="userCan('container_type_read_all')">Типы контейнеров</NavigationItem>
        <NavigationItem :to="'garbage_type'" >Типы Отходов</NavigationItem>
        <NavigationItem :to="'platform_type'" v-if="userCan('container_platform_type_read_all')">Типы КП</NavigationItem>
        <NavigationItem :to="'failure_reason'" v-if="userCan('failure_reason_read_all')">Причины невывоза</NavigationItem>
        <NavigationItem to='early completion' v-if="userCan('work_order_cancelation_reason_read_all')">Причины досрочного<br />завершения рейса</NavigationItem>
        <NavigationItem :to="'breakdown_type'" v-if="userCan('breakdowns_type_read_all')">Типы проблем</NavigationItem>
        <NavigationItem :to="'weight_cancel_reason'" >Причины отмены<br/>взвешиваний</NavigationItem>
      </NavigationGroup>

<!--       <NavigationGroup title="Подготовка производства" section="preproduction" icon="mdi mdi-image-filter-none" v-if="userCan('container_read_all') || userCan('container_platform_read_all')">
        <NavigationItem :to="'containers'" v-if="userCan('container_read_all')">Контейнеры</NavigationItem> -->
        <!-- <NavigationItem :to="'platforms'" v-if="userCan('container_platform_read_all')">Площадки</NavigationItem> -->
<!--       </NavigationGroup> -->

      <NavigationGroup title="Инвентаризация" section="inventory" icon="mdi mdi-map-marker-multiple" v-if="inventoryMapPermissions || inventoryPlatformPermissions">
        <NavigationItem :to="'inventory'" v-if="inventoryMapPermissions">На карте</NavigationItem>
        <NavigationItem to='inventory_platform' v-if="inventoryPlatformPermissions">Контейнерные площадки</NavigationItem>
      </NavigationGroup>

      <NavigationGroup title="Автопарк" section="carpark" icon="mdi mdi-truck" v-if="userCan('vehicle_read_all') || back_office">
        <NavigationItem to='vehicle' v-if="userCan('vehicle_read_all')">Автомобили</NavigationItem>
      </NavigationGroup>

      <NavigationGroup title="Факт" section="fact" icon="mdi mdi-map-marker" v-if="userCan('monitoring_read')">
        <NavigationItem :to="sizeOnlineMonitoring">Онлайн мониторинг</NavigationItem>
        <NavigationItem to="mobile">Мобильный версия</NavigationItem>
        <NavigationItem to='online_monitoring_photo'>Фотографии</NavigationItem>
        <!-- <NavigationItem to='fact_norm'>Нормативы</NavigationItem> -->
      </NavigationGroup>
      
      <!-- <NavigationGroup title="Технический отдел" section="technicalDepartment" icon="mdi mdi-math-compass" > -->
      <NavigationGroup title="Технический отдел" section="technicalDepartment" icon="mdi mdi-math-compass" v-if="back_office">
        <NavigationItem to='vehicle_sensor'>Устройства Gserver</NavigationItem>
        <NavigationItem to='all_list_sensor_components'>Список всех компонентов</NavigationItem>
      </NavigationGroup>

      <NavigationGroup title="Логистика" section="logistic" icon="mdi mdi-math-compass" v-if="!back_office">
        <NavigationItem v-if="userCan('location_show')" to='polygon'>Полигоны</NavigationItem>
      </NavigationGroup>

      <NavigationGroup title="Интеграция" section="Integration" icon="mdi mdi-directions-fork" v-if="userCan('superadmin') || userCan('admin')" >
        <NavigationItem to='Integration'>Интеграция c РГсофт</NavigationItem>
      </NavigationGroup>

      <li >
        <a style="font-weight: bold; font-size: 20px; cursor: pointer; color: #8f8f8f;">
          SRP
        </a>
        <ul class="nav-second-level collapse">
          <li v-for="item of urlLogistick" :key="item?.name">
            <a :href="item?.url">
              {{ item?.name }}
            </a>
          </li>
        </ul>
      </li>

      <!-- <div style="position: relative">
              <a @mouseover="isAtiveCursorUrlLogistick = true" @mouseout="isAtiveCursorUrlLogistick = false" style="font-weight: bold; font-size: 20px; cursor: pointer; display: flex; justify-content: center; color: #8f8f8f;" :href="urlLogistick" target="_blank">SRP</a>
              <p v-if="!urlLogistick && isAtiveCursorUrlLogistick" style="position: absolute; bottom: -40px; left: 60px; width: 200px; background-color: #093864; color: white; padding: 5px; border-radius: 5px;">Ссылки нет. Пожалуйста добавьте url в карточке организации</p>
      </div> -->

      <!-- <NavigationGroup title="Экран руководителя" section="autopark" icon="mdi mdi-book" v-if="userCan('monitoring_read')">
        <NavigationItem to='screen_manager'>Экран руководителя</NavigationItem>
      </NavigationGroup> -->

    </ul>
  </div>
</template>
<script>
  import {mapGetters} from 'vuex';
  import NavigationItem from '@/components/Navigation/NavigationItem';
  import NavigationGroup from '@/components/Navigation/NavigationGroup';

  export default {
        components: {NavigationItem, NavigationGroup},
        data: function () {
            return {
                activeItem: null,
                isAtiveCursorUrlLogistick: false,
            }
        },
        mounted() {
          
        },
        computed: {
          ...mapGetters({
            userCan: "user/userCan",
            sizeOnlineMonitoring: "navigation/sizeOnlineMonitoring",
            organisations: "user/userOrganisations",
          }),
          inventoryPlatformPermissions() {
            return this.userCan('container_platform_card_read_all') && this.userCan('card_status_read_all') && this.userCan('container_platform_type_read_all');
          },
          inventoryMapPermissions() {
            return this.userCan('container_platform_card_read_all') && this.userCan('container_card_read_all') && this.userCan('card_status_read_all') && this.userCan('container_platform_type_read_all');
          },
          urlLogistick() {
            let result = [];
            if (this.organisations.length > 0) {
              this.organisations.forEach((val) => {
                if (val.hostname) {
                  result.push({name: val.name, url: new URL(`https://${val.hostname}`)});                
                }
              })
            }

            return result;
          },
          back_office() {
            return this.userCan('technical_department_root') || Boolean(process.env.VUE_APP_LOCAL_DEV);
          }
        },
    }
</script>
