const defaultState = () => ({
  isUpdateMap: false,
})

export const rework_online_monitoring_map = {
  namespaced: true,
  state: defaultState(),
  getters: {
    getIsUpdateMap: state => state.isUpdateMap,
  },
  actions: {
    setIsUpdateMap({ commit }, payload) {
      commit('setIsUpdateMap', payload);
    },
    resetState({ commit }) {
      commit('resetState');
    },
  },
  mutations: {
    setIsUpdateMap(state, payload) {
      state.isUpdateMap = payload;
    },
    resetState(state) {
      Object.assign(state, defaultState());
    },
  }
}