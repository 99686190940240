export const online_monitoring = {
  namespaced: true,
  state: {
    organisation: {},
    isVisibleModalOmReport: false,
    isVisibleModalReportTransporterValue: false,
    isVisibleModalReportWorkDrivers: false,
    isVisibleModalReportDelay: false,
    isVisibleModalReportNonExportAct: false,
  },
  getters: {
    organisation: (state) => {
      return state.organisation;
    },
    get_is_visible_modal_om_report: (state) => {
      return state.isVisibleModalOmReport;
    },
    get_is_visible_modal_report_transporter_value: (state) => {
      return state.isVisibleModalReportTransporterValue;
    },
    get_is_visible_modal_report_work_drivers: (state) => {
      return state.isVisibleModalReportWorkDrivers;
    },
    get_is_visible_modal_report_delay: (state) => {
      return state.isVisibleModalReportDelay;
    },
    get_is_visible_modal_report_non_export_act: (state) => {
      return state.isVisibleModalReportNonExportAct;
    },
  },
  actions: {},
  mutations: {
    set_organisation(store, organisation) {
      store.organisation = organisation;
    },
    set_is_visible_modal_om_report(store, payload) {
      store.isVisibleModalOmReport = payload;
    },
    set_is_visible_modal_report_transporter_value(store, payload) {
      store.isVisibleModalReportTransporterValue = payload;
    },
    set_is_visible_modal_report_work_drivers(store, payload) {
      store.isVisibleModalReportWorkDrivers = payload;
    },
    set_is_visible_modal_report_delay(store, payload) {
      store.isVisibleModalReportDelay = payload;
    },
    set_is_visible_modal_report_non_export_act(state, payload) {
      return state.isVisibleModalReportNonExportAct = payload;
    },
  },
};
