const defaultState = () => ({
  isUpdateStats: false,
})

export const rework_online_monitoring_stats = {
  namespaced: true,
  state: defaultState(),
  getters: {
    getIsUpdateStats: state => state.isUpdateStats,
  },
  actions: {
    setIsUpdateStats({ commit }, payload) {
      commit('setIsUpdateStats', payload);
    },
    resetState({ commit }) {
      commit('resetState');
    },
  },
  mutations: {
    setIsUpdateStats(state, payload) {
      state.isUpdateStats = payload;
    },
    resetState(state) {
      Object.assign(state, defaultState());
    },
  }
}