<template>
  <div class="input-component-container">
    <div v-if="$slots.title" class="input-label">
      <slot name="title"></slot>
    </div>
    <div class="input-wrapper">
      <input
        @input="inputChange($event, $attrs.type)"
        @keypress="handleKeypress($event)"
        :value="inputText"
        @focus="$emit('focus')""
        :disabled="disabled"
        :placeholder="placeholder"
        :type="$attrs.type ? $attrs.type : 'text'"
        :min="$attrs.min"
        :max="$attrs.max"
        :pattern="$attrs.pattern"
        :step="$attrs.step"
        :maxlength="$attrs.maxlength"
        class="input-box"
        :id="customId"
        :class="[
          disabled? 'input-disabled': '',
          inputText? 'input-active': '',
          danger.status? 'input-box--danger': '',
          required? 'input-required': '',
          `input-box--${size}`
        ]"
        :style="inputStyle"
      >
      <div
        v-if="inputText && !disabled"
        @click="clearInput()"
        :class="[`cross--${size}`]"
        class="cross"
        :style="crossStyle"
      >
        <img src="@/assets/restyle/images/shared/icons/cross_2.svg" alt="">
      </div>
    </div>
    <div v-if="$slots.required && required" class="input-required-label" :style="{ 'bottom': requiredMessageBottomPosition }">
      <slot name="required"></slot>
    </div>
    <small class="danger-text" v-if="danger.text">
      {{ danger.text }}
    </small>
  </div>
</template>

<script>
  // Использовать компонент с помощью v-model='variable'.
  // Ширина по умолчанию 590px, можно задать свою.
  export default {
    name: 'RestyleInput',
    props: {
      inputText: {
        default: '',
      },
      disabled: {
        default: false,
        type: Boolean
      },
      placeholder: {
        default: null,
        type: String
      },
      required: {
        default: false,
        type: Boolean
      },
      danger: {
        type: Object,
        default: () => {
          return {
            text: '',
            status: false,
          }
        },
      },
      requiredMessageBottomPosition: {
        default: 'auto',
        type: String,
      },
      customId: {
        type: String,
      },
      size: {
        type: String,
        default: 'normal'
      },
      white: {
        type: Boolean,
        default: false,
      },
      inputStyle: {
        type: Object,
        default: () => ({}),
      },
      crossStyle: {
        type: Object,
        default: () => ({}),
      },
      // Если true, запрещает ввод отрицательных значений
      noNegative: {
        type: Boolean,
        default: false
      },
      countNumberAfterPoint: {
        type: Number,
      },
    },
    model: {
      prop: 'inputText',
      event: 'inputChange'
    },
    methods: {
      clearInput() {
        this.$emit('inputChange', '');
      },
      inputChange(event, type) {
        let target = event.target;
        let result = target.value;
        // Если включен noNegative и значение отрицательное, убираем минус
        if (this.noNegative) {
          result = target.value.replace(/^-/, '');
        }

        // Ограничиваем количество знаков после точки до указанных в пропсе
          if (this.countNumberAfterPoint) {
            const maxDecimals = this.countNumberAfterPoint;
            const parts = result.split('.');
            
            if (parts.length === 2 && parts[1].length >= maxDecimals && event.target.selectionStart > result.indexOf('.')) {
              event.preventDefault();
            }
          }

        this.$emit('inputChange', result);
      },
      handleKeypress(event) {
        const currentValue = event.target.value;
          if (event.key === 'Enter') {
            this.$emit('enter', event);
          }
          if (this.noNegative && event.key === '-') {
            event.preventDefault();
          }
            // Ограничиваем количество знаков после точки
          if (this.countNumberAfterPoint && currentValue.includes('.')) {
            const decimals = currentValue.split('.')[1].length;
            if (decimals >= this.countNumberAfterPoint) {
              event.preventDefault();
            }
          }
      }
    },
  }
</script>

<style lang="scss" scoped>
.input-component-container {
  color: #1D1871;
  width: 590px;
  position: relative;
}

.input-label {
  font-weight: 400;
  font-size: 23px;
  line-height: 31px;
  margin-bottom: 15px;
}

.input-box {
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  background: #EDFAFA;
  border-radius: 10px;
  height: 52px;
  padding: 12px 20px 12px 20px;
  width: 100%;

  &--danger {
    outline: 1px solid #EB6363;
  }

  &--small {
    padding: 6px 10px;
    height: 30px;
    border-radius: 5px;
    background-color: #fff;
    outline: 1px solid #D1D9E6 !important;
    font-size: 16px;
    line-height: normal;
  }

  &:focus {
    outline: 1px solid $color-success;
  }
  &:hover {
    outline: 1px solid $color-success;
  }
}

.input-wrapper {
  position: relative;
}
.cross {
  position: absolute;
  right: 17px;
  top: 33%;
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &--small {
    right: 10px;
    width: 10px;
    height: 10px;
  }
}

.input-disabled {
  cursor: not-allowed;
  background: #D7E8E8;
  color: #62849D;
}

input::placeholder {
  color: #62849D;
}

.input-active {
  outline: 1px solid $color-success;
  padding-right: 50px;
}

.input-required {
  outline: 1px solid #EB6363;
  &-label {
    position: absolute;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #EB6363;
  }
}
.danger-text {
  padding-top: 4px;
  display: inline-block;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #EB6363;
}
</style>