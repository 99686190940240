<template>
  <RestyleModal
    @close="$emit('close')"
    @save="downloadReport"
    @cancel="$emit('close')"
    :sendDisabled="disabledDownloadButton"
  >
    <template slot="header">
      Отчёт по СЗ
    </template>
    <div class="" style="min-height: 350px">
      <div class="mb-4">
        <DateRangePicker
          @dateChange="dateRange = $event"
          :dates="dateRange"
          :dateSelectBoxStyle="dateSelectBoxStyle"
          :minDate="minDate"
          :maxDate="maxDate"
          :canClearDate="false"
          class="date-filter mr-4"
          />
      </div>
      <div>
        <SearchFilterMultiSelect
          :allSelectOptions="computedUserOrganisations"
          :inputText="searchUserOrganisation"
          @inputChange="searchUserOrganisation = $event"
          @selectedOptionsChange="selectedUserOrganisation = $event"
          :selectedOptions="selectedUserOrganisation"
          placeholder="Организации"
          :wordForms="['организация', 'организации', 'организаций']"
          :wordFormsSelected="['Выбрана', 'Выбрано', 'Выбрано']"
          class="text-left mr-4"
          style="width: 280px"
        />
      </div>
      <div class="skip-telemetry" @click.self="skipTelemetry = !skipTelemetry">
        <CheckboxSquare
          v-model="skipTelemetry"
        />
        Без навигации
      </div>
    </div>
    <template slot="confirmButtonText"> Скачать </template>
  </RestyleModal>
</template>
<script>
import { request } from "@/_helpers";
import { mapGetters, mapMutations } from "vuex";

import DateRangePicker from "@/components/restyle/calendar/DateRangePicker.vue";
import SearchFilterMultiSelect from "@/components/restyle/primitives/filters/SearchFilterMultiSelect.vue";
import { transformOrgsIntoOrgTree } from "@/_helpers/functions/functions";
import CheckboxSquare from '@/components/restyle/primitives/checkboxes/checkbox_square.vue';

const initPrevDate = new Date(new Date() - 1000 * 60 * 60 * 24);
const splitInitPrevDate = initPrevDate
  .toISOString()
  .substring(0, 10)
  .split("-");
const normalizeDate = `${splitInitPrevDate[2]}.${splitInitPrevDate[1]}.${splitInitPrevDate[0]}`;

export default {
  name: "ResultCompleteTask",
  components: {
    DateRangePicker,
    SearchFilterMultiSelect,
    CheckboxSquare
  },
  data() {
    return {
      minDate: null,
      maxDate: initPrevDate,
      dateSelectBoxStyle: { width: "300px", height: "unset" },
      dateRange: {
        start: normalizeDate,
        end: normalizeDate,
      },
      searchUserOrganisation: "",
      selectedUserOrganisation: [],
      // disabledDates: null,
      skipTelemetry: false,
    };
  },
  computed: {
    ...mapGetters({
      userCan: "user/userCan",
      allOrgs: "organisations/all",
      userOrganisations: "user/userOrganisations",
    }),
    disabledDownloadButton() {
      return !(
        this.selectedUserOrganisation.length > 0 &&
        this.dateRange.start &&
        this.dateRange.end
      );
    },
    normalizeDateToAddInUrl() {
      const splitDateStart = this.dateRange.start?.split(".");
      const splitDateEnd = this.dateRange.end?.split(".");
      const result = {
        start: `${splitDateStart[2]}-${splitDateStart[1]}-${splitDateStart[0]}`,
        end: `${splitDateEnd[2]}-${splitDateEnd[1]}-${splitDateEnd[0]}`,
      };
      return result;
    },
    computedUserOrganisations () {
      let result = transformOrgsIntoOrgTree(this.userOrganisations)
      result = result.reduce((acc, item) => {
        acc.push(item)

        if (item.children?.length > 0) {
          for (const iterator of item.children) {
            acc.push({...iterator, itsChild: true})
          }
        }

        return acc
      }, [])
      
      return result
    }
  },
  created() {
    
  },
  methods: {
    ...mapMutations({
        setIsWaitAlertAfterRequest: "user_notifications/SET_IS_WAIT_ALERT_AFTER_REQUEST",
    }),
    downloadReport() {
      let url = new URL(`${process.env.VUE_APP_WN}/fact/container/report`);
      url.searchParams.append("api_token", localStorage.getItem("auth_token"));
      url.searchParams.append("begin_date", this.normalizeDateToAddInUrl.start);
      url.searchParams.append("end_date", this.normalizeDateToAddInUrl.end);
      this.selectedUserOrganisation.forEach((element, index) => {
        // url.searchParams.append('organisation_id', element.id); такой параметр тоже есть, для 1й орг.
        url.searchParams.append(`organisation_ids[]`, element.id);
      });
      url.searchParams.append("skip_telemetry", Number(this.skipTelemetry));

      request(url.href, "get")
        .then((res) => {
          if (res.success) {
            this.$emit('close')
            this.$store.dispatch('alert/alerts', { type: 'success', message: res.message });
            this.setIsWaitAlertAfterRequest(true);  
          } else {
            this.$store.dispatch('alert/alerts', { type: 'danger', message: res.message });
          }
        }).finally(() => {
            this.setIsWaitAlertAfterRequest(false); 
         })
    },
    // dragValue(event) {
    //   const { start } = event;
    //   if (start) {
    //     const normalizeStart = start.split('.').reverse().join('-')
    //     const dateStart = new Date(normalizeStart);        
    //     const rangeStart = new Date(
    //       dateStart.getFullYear(),
    //       dateStart.getMonth(),
    //       dateStart.getDate() - 91
    //     );
    //     const rangeEnd = new Date(
    //       dateStart.getFullYear(),
    //       dateStart.getMonth(),
    //       dateStart.getDate() + 91
    //       );
          
    //     this.disabledDates = [
    //       {
    //         start: null,
    //         end: rangeStart,
    //       },
    //       {
    //         start: rangeEnd,
    //         end: null,
    //       },
    //     ]
    //   } else {
    //     this.disabledDates = []
    //   }
    // },
    dateChange(event) {
      this.dateRange = event
      // this.disabledDates = []
    },
    // outsideDate() {
    //   this.disabledDates = []
    // },
  },
};
</script>

<style lang="scss" scoped>
.skip-telemetry {
  display: flex;
  align-items: center;
  margin-top: 20px;
    & .checkbox-item {
      margin-right: 15px;
    }
}
</style>