import { request } from "../../_helpers"

const defaultState = () => ({
  listWorkOrders: [],
  listSummaryWorkOrders: [],
  activeWorkOrder: null,
  activeContainerPlatform: null,
  viewAllWorkOrder: false,
  viewPlatformsOnMap: [],
  detailInfoContainerPlatform: null,
  isWosLoading: true,
  isWosSummaryLoading: false,
  isAddPlatformsModalOpen: null,
  isSummaryNeedsUpdate: true,
  isUpdateSummary: false,
  isUpdateWorkorders: false,
})

export const rework_online_monitoring_list_work_orders = {
  namespaced: true,
  state: defaultState(),
  getters: {
    getListWorkOrders: state => state.listWorkOrders,
    getListSummaryWorkOrders: state => state.listSummaryWorkOrders,
    getActiveWorkOrder: state => state.activeWorkOrder,
    getActiveContainerPlatform: state => state.activeContainerPlatform,
    getDetailInfoContainerPlatform: state => state.detailInfoContainerPlatform,
    getViewAllWorkOrder: state => state.viewAllWorkOrder,
    getViewPlatformsOnMap: state => state.viewPlatformsOnMap,
    getWosLoading: state => state.isWosLoading,
    getWosSummaryLoading: state => state.isWosSummaryLoading,
    getIsAddPlatformsModalOpen: state => state.isAddPlatformsModalOpen,
    getIsSummaryNeedsUpdate: state => state.isSummaryNeedsUpdate,
    getIsUpdateSummary: state => state.isUpdateSummary,
    getIsUpdateWorkorders: state => state.isUpdateWorkorders,
  },
  actions: {
    setIsUpdateSummary({ commit }, payload) {
      commit('setIsUpdateSummary', payload);
    },
    setIsUpdateWorkorders({ commit }, payload) {
      commit('setIsUpdateWorkorders', payload);
    },
    setIsSummaryNeedsUpdate({ commit }, payload) {
      commit('setIsSummaryNeedsUpdate', payload);
    },
    setListWorkOrders({commit}, payload) {
      commit('setListWorkOrders', payload);
    },
    setListSummaryWorkOrders({commit}, payload) {
      commit('setListSummaryWorkOrders', payload);
    },
    setActiveContainerPlatform({commit}, payload) {
      commit('setActiveContainerPlatform', payload);
      if (payload) {
        request(
          `${process.env.VUE_APP_WN}/fact/platform/${payload.id}`,
          "get"
        ).then((res) => commit('setDetailInfoContainerPlatform', res.success ? res.data : null))
      } else {
        commit('setDetailInfoContainerPlatform', null)
      }
    },
    setWosLoading({ commit }, payload) {
      commit('setWosLoading', payload);
    },
    setWosSummaryLoading({ commit }, payload) {
      commit('setWosSummaryLoading', payload);
    },
    clearActiveStateToDefaultValue({ commit }) {
      commit('clearActiveStateToDefaultValue');
    },
    clearAllState({ commit }) {
      commit('clearAllState');
    },
  },
  mutations: {
    setIsUpdateSummary(state, payload) {
      state.isUpdateSummary = payload;
    },
    setIsUpdateWorkorders(state, payload) {
      state.isUpdateWorkorders = payload;
    },
    setIsSummaryNeedsUpdate(state, payload) {
      state.isSummaryNeedsUpdate = payload;
    },
    setListWorkOrders(state, payload) {
      state.listWorkOrders = payload
    },
    setListSummaryWorkOrders(state, payload) {
      state.listSummaryWorkOrders = payload
    },
    setActiveWorkOrder(state, payload) {
      state.activeWorkOrder = payload
    },
    setActiveContainerPlatform(state, payload) {
      state.activeContainerPlatform = payload
    },
    setDetailInfoContainerPlatform(state, payload) {
      state.detailInfoContainerPlatform = payload
    },
    toggleViewAllWorkOrder(state) {
      state.viewAllWorkOrder = !state.viewAllWorkOrder
    },
    toggleViewPlatformsOnMap(state, payload) {
      state.viewPlatformsOnMap = state.viewPlatformsOnMap.includes(payload)? state.viewPlatformsOnMap.filter(e => e != payload): [...state.viewPlatformsOnMap, payload];
    },
    clearActiveStateToDefaultValue(state) {
      state.activeWorkOrder = null;
      state.activeContainerPlatform = null;
      state.viewAllWorkOrder = false;
      state.viewPlatformsOnMap = [];
      state.detailInfoContainerPlatform = null;
      state.isWosLoading = false;
    },
    clearAllState(state) {
      Object.assign(state, defaultState());
    },
    setWosLoading(state, payload) {
      state.isWosLoading = payload;
    },
    setWosSummaryLoading(state, payload) {
      state.isWosSummaryLoading = payload;
    },
    setAddPlatformsModalOpen(state, payload) {
      state.isAddPlatformsModalOpen = payload;
    }
  }
}