<template>
  <div class="restyle-modal" >
    <div class="restyle-modal-section" @click.self="handleOutsideClick">
      <div class="restyle-modal-content" :class="modalBorder" :style="styleModalContent">
        <div class="restyle-modal-scroll-wrapper scrollbar scrollbar--primary" :class="{ 'no-footer': !use_footer }" ref="scrollWrapper" :style="{
          height: customHeight,
          width: customWidth,
        }">
          <div class="restyle-modal-header-plus-body-wrapper">
            <div class="restyle-modal-content-header-wrapper">
              <div class="restyle-modal-content-header" v-if="use_header">
                <slot name="header"></slot>
              </div>
              <div v-else></div>
              <div class="restyle-modal-close" @click="$emit('close')" v-if="close" :id="customId ? `${customId}-cross-button` : null">
                <img src="@/assets/restyle/images/close_x.svg" alt="">
              </div>
            </div>
            <div class="restyle-modal-content-data" :style="styleModalContentData">
              <slot></slot>
            </div>
          </div>
        </div>
        <div class="restyle-modal-content-footer" :class="footerHorizontalLine" v-if="use_footer">
          <slot name="footer">
            <restyle_button
              v-if="showConfirmButton"
              @click="$emit('save')"
              class="save-button"
              :class="{ 'danger-red-background': color === 'red' }"
              :disabled="sendDisabled"
              style="margin-right: 25px"
              :id="customId ? `${customId}-save-button` : null"
            >
              <slot name="confirmButtonText">{{ confirmButtonText }}</slot>
            </restyle_button>
            <restyle_button
              v-if="showCancelButton"
              @click="$emit('cancel')"
              class="outline cancel-button"
              :id="customId ? `${customId}-cancel-button` : null"
            >
              <slot name="cancelButtonText">{{ cancelButtonText }}</slot>
            </restyle_button>
            <span class="footer-message" v-if="$slots.footerMessage">
              <slot name="footerMessage"></slot>
            </span>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RestyleModal',
  props: {
    close: {
      type: Boolean,
      default: true,
    },
    use_header: {
      type: Boolean,
      default: true,
    },
    use_footer: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      validator: function (value) {
        return ['red', 'green'].includes(value);
      },
      default: 'green',
    },
    sendDisabled: {
      type: Boolean,
      default: false,
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    showConfirmButton: {
      type: Boolean,
      default: true,
    },
    horizontalLineAlwaysVisible: {
      type: Boolean,
      default: false,
    },
    styleModalContent: {
      type: Object,
      default: () => {},
    },
    styleModalContentData: {
      type: Object,
      default: () => {},
    },
    customId: {
      type: String,
    },
    confirmButtonText: {
      type: String,
      default: 'Сохранить'
    },
    cancelButtonText: {
      type: String,
      default: 'Отмена'
    },
    customHeight: {
      type: String,
    },
    customWidth: {
      type: String,
    },
    isPreventClosingOnClickOutside: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isScrollPresent: false,
      isUserScrolling: false,
    }
  },
  computed: {
    modalBorder() {
      if (this.color === 'red') {
        return 'red-border';
      }
      return 'green-border';
    },
    footerHorizontalLine() {
      if (this.horizontalLineAlwaysVisible && this.color === 'red') {
        return 'red-border-top';
      }
      if (this.horizontalLineAlwaysVisible && this.color === 'green') {
        return 'green-border-top';
      }
      if ((this.isScrollPresent || this.isUserScrolling) && this.color === 'red') {
        return 'red-border-top';
      }
      if ((this.isScrollPresent || this.isUserScrolling) && this.color === 'green') {
        return 'green-border-top';
      }
      return '';
    },
  },
  mounted() {
    this.checkScroll();
    this.$refs.scrollWrapper.addEventListener('scroll', this.userStartedScrolling);
  },
  beforeDestroy() {
    this.$refs.scrollWrapper.removeEventListener('scroll', this.userStartedScrolling);
  },
  methods: {
    checkScroll() {
      if (this.$refs.scrollWrapper.scrollHeight > this.$refs.scrollWrapper.clientHeight) {
        this.isScrollPresent = true;
      } else {
        this.isScrollPresent = false;
      }
    },
    userStartedScrolling() {
      this.isUserScrolling = true;
    },
    handleOutsideClick() {
      if (!this.isPreventClosingOnClickOutside) {
        this.$emit('close');
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.restyle-modal {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  &::after {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #F8FBFA;
    opacity: .6;
  }
}

.restyle-modal-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.restyle-modal-content {
  margin: 80px 60px 30px 60px;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  max-height: calc(100% - 80px - 30px);
  background: #FFFFFF;
  box-shadow: 0px 12.2756px 32.2234px 6.13779px rgba(129, 184, 235, 0.15);
  border-radius: 30px;
} 

.restyle-modal-close {
  width: 25px;
  height: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 5px;
  align-self: flex-start;
}

.restyle-modal-content-header {
  font-weight: 400;
  font-size: 38px;
  line-height: 52px;
  color: #1D1871;
  margin-right: 100px;

  & h1, h2, h3, h4, h5, h6, p, span, div {
    font-weight: 400;
    font-size: 38px;
    line-height: 52px;
    color: #1D1871;
  }
}

.restyle-modal-content-data {
  margin-bottom: 30px;
  padding-left: 25px;
}

.restyle-modal-content-footer {
  padding: 20px 40px;
}

.restyle-modal-content-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.restyle-modal-scroll-wrapper {
  overflow-y: auto;
  scrollbar-gutter: stable;
  margin-top: 20px;
}

.no-footer {
  margin-bottom: 20px;
}

.restyle-modal-header-plus-body-wrapper {
  padding-top: 15px;
  padding-left: 40px;
  padding-right: 20px;
}

.green-border-top {
  border-top: 1px solid $color-smartro-green-bright;
}

.red-border-top {
  border-top: 1px solid $color-error;
}

.green-border {
  border: 1px solid $color-smartro-green-bright;
}

.red-border {
  border: 1px solid $color-error;
  box-shadow: none;
}

.footer-message {
  font-family: Noto Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: $color-error;
}

.save-button, .cancel-button {
  margin-right: 20px;
}

</style>