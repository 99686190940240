<template>
  <div
    ref="clickOutsideWrapper"
    class="filter-wrapper"
    @click="!disabledSelect && toggleOptions()"
  >
    <div
      @mouseenter="onFilterHover($event)"
      @mouseleave="onFilterUnhover()"
      class="filter-box"
      :class="{ 'select-required': required, 'filter-box--active': selectedOption }"
    >  
      <template v-if="!disabledSelect">
        <img
          v-if="selectedOption && allowEmpty && hiddenCrossVar"
          @click.stop="onCrossClick()"
          class="cross"
          :class="[`cross--${size}`]"
          src="@/assets/restyle/images/shared/icons/cross_2.svg"
          alt=""
        />
        <img
          v-else
          class="search-icon-default"
          :class="[`search-icon-default--${size}`]"
          src="@/assets/restyle/images/shared/icons/filter_search_default.svg"
          alt=""
        />
      </template>
      <input
        type="text"
        ref="inputField"
        @input="inputChangeHandler($event)"
        @focus="onInputFocus()"
        @blur="onInputBlur()"
        :value="inputText"
        :placeholder="placeholder"
        class="input-box"
        :class="[ !isFocused && selectedOption? 'placeholder-active': '', `input--${size}` ]  "
        :id="customId"
        :disabled="disabledSelect"
      />
      <div class="select-error" v-if="required">
        <img class="select-error-image" src="@/assets/restyle/images/shared/select/error.svg" alt=""/>
      </div>
      <img
        class="arrow-icon"
        :class="{ 'arrow-down': optionsVisible }"
        :src="require(`@/assets/restyle/images/shared/icons/arrow-right-gray${disabledSelect? '-disabled': ''}.svg`)"
        alt="" />
    </div>
    <div
      v-if="optionsVisible"
      class="select-option-wrapper scrollbar scrollbar--secondary"
      :style="{ 'max-height': optionsMaxHeight }"
      :class="[`select-option-wrapper--${size}`]"
    >
      <template v-for="(option, index) in filteredOptions">
        <div @click="selectOption(option)" class="select-option" :key="`option-${index}`">
          <slot name="option" :option="option">
            {{ option.name }}
          </slot>
        </div>
        <template v-if="option?.children && useChildOptions">
          <div
            v-for="(subOption, subOptionIndex) in option.children"
            :key="`option-${index}-subOption-${subOptionIndex}`"
            @click="selectOption(subOption)"
            class="select-option child-option"
          >
            <slot name="option" :option="subOption">
              {{ subOption.name }}
            </slot>
          </div>
        </template>
      </template>
    </div>
    <div v-if="$slots.required && required" class="select-required-label">
      <slot name="required"></slot>
    </div>
    <RestyleTooltip
      v-if="hoverEvent && showTooltip && textOverflowing"
      :hoverEvent="hoverEvent"
      :tooltipDelay="tooltipDelay"
      :tooltipTail="tooltipTail"
      :tooltipText="tooltipText"
    />
  </div>
</template>

<script>
export default {
  name: 'SearchFilterSelect',
  props: {
    inputText: {
      default: '',
      type: String,
      required: true,
    },
    placeholder: {
      default: null,
      type: String,
    },
    options: {
      required: true,
      type: Array
    },
    selectedOption: {
      default: null,
    },
    optionsMaxHeight: {
      default: '250px',
      type: String
    },
    showTooltip: {
      default: false,
      type: Boolean,
    },
    tooltipDelay: {
      default: 0,
      type: Number,
    },
    tooltipTail: {
      default: true,
      type: Boolean,
    },
    allowEmpty: {
      default: true,
      type: Boolean,
    },
    useChildOptions: {
      default: false,
      type: Boolean,
    },
    required: {
      default: false,
      type: Boolean
    },
    viewKey: {
      type: String,
      default: 'name',
    },
    customId: {
      type: String,
    },
    size: {
      type: String,
      default: 'normal',
    },
    disabledSelect: {
      type: Boolean,
      default: false,
    },
    hiddenCrossVar: {
      type: Boolean,
      default: true,
    },
    isNotCleanValue: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isHovered: false,
      isFocused: false,
      optionsVisible: false,
      hoverEvent: null,
      textOverflowing: false,
    }
  },
  watch: {
    optionsVisible(newValue) {
      if (!newValue && this.isNotCleanValue === false) {
        this.$emit('inputChange', '');
      }
    }
  },
  computed: {
    filteredOptions() {
      if (this.inputText && !this.useChildOptions) {
        let lowerCasedFilter = this.inputText.toLowerCase();
        let result = [];
        this.options.forEach(option => {
          if (option.name.toLowerCase().includes(lowerCasedFilter)) {
            result.push(option);
          }
        });
        return result;
      }
      if (this.inputText && this.useChildOptions) {
        let lowerCasedFilter = this.inputText.toLowerCase();
        let result = [];
        this.options.forEach(option => {
          let matchedSubOptions = option.children?.filter(subOption =>
            subOption.name.toLowerCase().includes(lowerCasedFilter));
          if (option.name.toLowerCase().includes(lowerCasedFilter) || matchedSubOptions?.length > 0) {
            result.push({
              ...option,
              children: matchedSubOptions
            });
          }
        });
        return result;
      }
      return this.options;
    },
  },
  mounted() {
    document.addEventListener('click', this.clickOutsideClose);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickOutsideClose);
  },
  methods: {
    onFilterHover(event) {
      this.isHovered = true;
      this.checkIfTextOverflowing();
      this.hoverEvent = event;
      this.tooltipText = this.placeholder;
    },
    onFilterUnhover() {
      this.isHovered = false;
      this.hoverEvent = null;
    },
    toggleOptions() {
      this.optionsVisible = !this.optionsVisible;
    },
    clickOutsideClose(event) {
      if (!this.$refs.clickOutsideWrapper?.contains(event.target)) {
        this.optionsVisible = false;
      }
    },
    selectOption(option) {
      this.optionsVisible = false;
      this.$emit('selectChange', option);
    },
    inputChangeHandler(event) {
      this.$emit('inputChange', event.target.value);
    },
    onCrossClick() {
      this.$emit('inputChange', '');
      this.optionsVisible = false;
      this.$emit('selectChange', null);
    },
    checkIfTextOverflowing() {
      const inputElement = this.$refs.inputField;
      const inputValueBefore = inputElement.value;
      inputElement.value = inputElement.placeholder;
      this.textOverflowing = inputElement.scrollWidth > inputElement.clientWidth;
      inputElement.value = inputValueBefore;
    },
    onInputFocus() {
      this.hoverEvent = null;
      this.isFocused = true;
    },
    onInputBlur() {
      this.isFocused = false;
    }

  }
}
</script>

<style lang="scss" scoped>
.input-box {
  padding: 12px 40px 12px 40px;
  box-shadow: 0px 2px 8px rgba(23, 21, 55, 0.1);
  border-radius: 5px;
  background-color: $color-restyle-white;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: $color-page-text;
}
.search-icon-active,
.search-icon-default {
  width: 18px;
  height: 18px;
  position: absolute;
  top: calc(33% - 1px);
  left: 12px;

  &--small {
    width: 12px;
    height: 12px;

  }
}
input {
  &:focus {
    outline: 1px solid $color-smartro-green-bright;
    box-shadow: none;
  }
  &:disabled {
    padding-left: 12px;
    background: #EEF1F4;
    outline: 1px solid #D1D9E6;
  }
}
input::placeholder {
  color: $color-gray-text;
  font-family: Noto Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.filter-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  color: $color-page-text;
  font-family: Noto Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
input {
  width: 100%;
}
input.placeholder-active::placeholder {
  color: $color-page-text;
}
.select-option {
  overflow-wrap: break-word;

  &:hover {
    background: #1D1871;
    color: #FFFFFF;
    cursor: pointer;
    & *{
      color: #FFFFFF;
    }
  }

  padding: 10px 20px 10px 20px;

  &:first-child {
    border-radius: 9.4px 9.4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 9.4px 9.4px;
  }
}
.select-option-wrapper {
  border-radius: 9.4px;
  box-shadow: 0px 3.8px 15.1px rgba(23, 21, 55, 0.1);
  background: #FFFFFF;
  position: absolute;
  z-index: 1;
  font-size: 20px;
  line-height: 27px;
  width: 100%;
  overflow-y: auto;
  max-height: 250px;
  top: 52px;

  &--small {
    top: 34px;
  }
}
.arrow-icon {
  position: absolute;
  top: calc(33% + 2px);
  right: 12px;
  width: 11px;
  height: 11px;
}
.arrow-down {
  transform: rotate(90deg);
}
.filter-box {
  width: 100%;

  &--active {
    border-radius: 5px;
    outline: 1px solid #466ABF;
    & input {
      box-shadow: none;
    }
  }
  &:hover {
    & input {
      outline: 1px solid $color-smartro-green-bright;
      box-shadow: none;
      &:disabled {
        outline: 1px solid #D1D9E6;
      }
    }
  }
}
.cross {
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  top: 33%;
  left: 12px;

  &--small {
    left: 10px;
    width: 12px;
    height: 12px;
  }
}
.child-option {
  padding: 10px 20px 10px 50px;
}
.select-required {
  & input {
    outline: 1px solid #EB6363 !important;
    padding-right: 65px;
  }
  &-label {
    position: absolute;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #EB6363;
    bottom: -25px;
  }
}
.select-error {
  position: absolute;
  right: 30px;
  top: calc(33% - 3px);
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  &-image {
    width: 21px;
    height: 21px;
  }
}

.input {
  &--small {
    padding: 4px 27px 4px 27px;
    font-size: 16px;
  }
}
</style>