<template>
  <div class="reference-block">
    <div class="reference-block-link" id="report-work-drivers">
      <div class="link-flex" @click="setIsVisibleModalReportWorkDrivers(true)">
        {{ getByHotSpotRouteTitleMenu('report-work-drivers') }}
        <div
          v-if="isActiveHotSpot('report-work-drivers')?.id"
          class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: currentTooltipForHotSpot('report-work-drivers'),
          })"
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');"
        >
          Новое
        </div>
      </div>
    </div>
    <div class="reference-block-link" v-if="userCan('om_report')">
      <div class="link-flex" @click="setIsVisibleModalOMReport(true)">
        {{ getByHotSpotRouteTitleMenu('report-on-sz') }}
        <div
          v-if="isActiveHotSpot('report-on-sz')?.id"
          class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: currentTooltipForHotSpot('report-on-sz'),
          })"
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');"
        >
          Новое
        </div>
      </div>
    </div>
    <div class="reference-block-link" id="report-delay">
      <div class="link-flex" @click="setIsVisibleModalReportDelay(true)">
        {{ getByHotSpotRouteTitleMenu('report_delay') }}
        <div
          v-if="isActiveHotSpot('report_delay')?.id"
          class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: currentTooltipForHotSpot('report_delay'),
          })"
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');"
        >
          Новое
        </div>
      </div>
    </div>
    <div class="reference-block-link">
      <div class="link-flex" @click="setIsVisibleModalReportTransporterValue(true)">
        {{ getByHotSpotRouteTitleMenu('report-transporter-value') }}
        <div
          v-if="isActiveHotSpot('report-transporter-value')?.id"
          class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: currentTooltipForHotSpot('report-transporter-value'),
          })"
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');"
        >
          Новое
        </div>
      </div>
    </div>

    <div class="reference-block-link" id="non-export-act" v-if="userCan('show_failure_act')">
      <div class="link-flex" @click="setIsVisibleModalReporNoNExportAct(true)">
        {{ getByHotSpotRouteTitleMenu('non-export-act') }}
        <div
          v-if="isActiveHotSpot('non-export-act')?.id"
          class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: currentTooltipForHotSpot('non-export-act'),
          })"
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');"
        >
          Новое
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';

export default {
  methods: {
    ...mapMutations({
      setIsVisibleModalOMReport: "online_monitoring/set_is_visible_modal_om_report",
      setIsVisibleModalReportTransporterValue: "online_monitoring/set_is_visible_modal_report_transporter_value",
      setIsVisibleModalReportWorkDrivers: "online_monitoring/set_is_visible_modal_report_work_drivers",
      setIsVisibleModalReportDelay: "online_monitoring/set_is_visible_modal_report_delay",
      setIsVisibleModalReporNoNExportAct: "online_monitoring/set_is_visible_modal_report_non_export_act",
    }),
    isActiveHotSpot(menu) {
      const rusNameMenu = this.getByHotSpotRouteTitleMenu(menu)
      const result = this.allListActiveHotSpots.find(item => {
        return item.menu === rusNameMenu
      });
      return result;
    },
    currentTooltipForHotSpot(menu) {
      const hotSpot = this.isActiveHotSpot(menu);
      if (Object.entries(hotSpot).length &&  hotSpot.tooltip) {
        return hotSpot.tooltip
      } 
      return ''
    }
  },
  computed: {
    ...mapGetters({
      userCan: "user/userCan",
      getByHotSpotRouteTitleMenu: "hotspots/getByHotSpotRouteTitleMenu",
      allListActiveHotSpots: "hotspots/allListActiveHotSpots",
    })
  }
};
</script>

<style lang="scss" scoped>
.reference-block {
  padding: 20px 25px;
  z-index: 1;
  display: grid;
  row-gap: 20px;
  position: absolute;
  top: 50px;
  left: 0;
  width: 327px;
  border-radius: 5px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 566px;

  &::after {
    margin-top: 2px;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 398px;
    height: calc(100% - 2px);
    background: #1d4b7f;
    box-shadow: 4px 4px 8px 2px rgba(57, 134, 144, 0.5);
    border-radius: 5px;
  }

  &-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    height: 100%;

    & a {
      display: inline-block;
      width: 100%;
      height: 100%;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
    }

    &:hover {
      color: #44ff8f;
    }
  }
  .link-report {
    color: #ffffff;
    &:hover {
      color: #44ff8f;
    }
  }
  .link-flex {
    display: flex;
    align-items: center
  }
}
</style>