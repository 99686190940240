import { render, staticRenderFns } from "./links.vue?vue&type=template&id=db5dfbbc&scoped=true"
import script from "./links.vue?vue&type=script&lang=js"
export * from "./links.vue?vue&type=script&lang=js"
import style0 from "./links.vue?vue&type=style&index=0&id=db5dfbbc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db5dfbbc",
  null
  
)

export default component.exports