var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-component-container"},[(_vm.$slots.title)?_c('div',{staticClass:"input-label"},[_vm._t("title")],2):_vm._e(),_c('div',{staticClass:"input-wrapper"},[_c('input',{staticClass:"input-box",class:[
        _vm.disabled? 'input-disabled': '',
        _vm.inputText? 'input-active': '',
        _vm.danger.status? 'input-box--danger': '',
        _vm.required? 'input-required': '',
        `input-box--${_vm.size}`
      ],style:(_vm.inputStyle),attrs:{"disabled":_vm.disabled,"placeholder":_vm.placeholder,"type":_vm.$attrs.type ? _vm.$attrs.type : 'text',"min":_vm.$attrs.min,"max":_vm.$attrs.max,"pattern":_vm.$attrs.pattern,"step":_vm.$attrs.step,"maxlength":_vm.$attrs.maxlength,"id":_vm.customId},domProps:{"value":_vm.inputText},on:{"input":function($event){return _vm.inputChange($event, _vm.$attrs.type)},"keypress":function($event){return _vm.handleKeypress($event)},"focus":function($event){return _vm.$emit('focus')}}}),(_vm.inputText && !_vm.disabled)?_c('div',{staticClass:"cross",class:[`cross--${_vm.size}`],style:(_vm.crossStyle),on:{"click":function($event){return _vm.clearInput()}}},[_c('img',{attrs:{"src":require("@/assets/restyle/images/shared/icons/cross_2.svg"),"alt":""}})]):_vm._e()]),(_vm.$slots.required && _vm.required)?_c('div',{staticClass:"input-required-label",style:({ 'bottom': _vm.requiredMessageBottomPosition })},[_vm._t("required")],2):_vm._e(),(_vm.danger.text)?_c('small',{staticClass:"danger-text"},[_vm._v(" "+_vm._s(_vm.danger.text)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }