const defaultState = () => ({
  contractToDelete: null,
})

export const contract_block_contract_list = {
  namespaced: true,
  state: defaultState(),
  getters: {
    contractToDelete: state => state.contractToDelete,
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    deleteContract({ commit }, payload) {
      commit('deleteContract', payload);
    },
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState());
    },
    deleteContract(state, payload) {
      state.contractToDelete = payload;
    },
  },
};