import {request} from "../_helpers";

const defaultState = () => ({
    chats: [],
    detailChat: null,
    loadingDetailChat: false,
})

export const messenger = {
    namespaced: true,
    state: defaultState(),
    getters: {
        getChats(state) {
            return state.chats
        },
        getDetailChat(state) {
            return state.detailChat
        },
        getLoadingDetailChat(state) {
            return state.loadingDetailChat
        },
    },
    actions: {
        resetState({ commit }) {
            commit('resetState');
        },
        setChats({commit}, payload) {
            commit('setChats', payload)
        },
        setDetailChat({commit}, payload) {
            commit('setDetailChat', payload)
        },
        setLoadingDetailChat({commit}, payload) {
            commit('setLoadingDetailChat', payload)
        },
    },
    mutations: {
        resetState(state) {
            Object.assign(state, defaultState());
        },
        setChats(state, payload) {
            state.chats = payload
        },
        setDetailChat(state, payload) {
            state.detailChat = payload
        },
        setLoadingDetailChat(state, payload) {
            state.loadingDetailChat = payload
        },
    }
}