<template>
  <RestyleModal
    @close="setIsVisibleModalReportDelay(false)"
    @cancel="setIsVisibleModalReportDelay(false)"
    @save="downloadReport"
    :sendDisabled="disabledDownloadButton"
  >
    <template slot="header">
      Отчёт по опозданиям
    </template>
    <div class="" style="min-height: 350px">
      <div class="mb-4">
        <DateRangePicker
          @dateChange="dateChange"
          :dates="dateRange"
          :dateSelectBoxStyle="dateSelectBoxStyle"
          :minDate="minDate"
          :maxDate="maxDate"
          :canClearDate="false"
          class="date-filter mr-4"
          />
      </div>
      <div>
        <SearchFilterMultiSelect
          :allSelectOptions="computedUserOrganisations"
          :inputText="searchUserOrganisation"
          @inputChange="searchUserOrganisation = $event"
          @selectedOptionsChange="selectedUserOrganisation = $event"
          :selectedOptions="selectedUserOrganisation"
          placeholder="Организации"
          :wordForms="['организация', 'организации', 'организаций']"
          :wordFormsSelected="['Выбрана', 'Выбрано', 'Выбрано']"
          class="text-left mr-4"
          style="width: 280px"
        />
      </div>
    </div>
    <template slot="confirmButtonText"> Скачать </template>
  </RestyleModal>
</template>

<script>
import { request } from "@/_helpers";
import DateRangePicker from "@/components/restyle/calendar/DateRangePicker.vue";
import { mapGetters, mapMutations } from "vuex";
import { transformOrgsIntoOrgTree } from "@/_helpers/functions/functions";
import SearchFilterMultiSelect from "@/components/restyle/primitives/filters/SearchFilterMultiSelect.vue";

const initPrevDate = new Date(new Date() - 1000 * 60 * 60 * 24);
const viewDateHandler = (myDate) => {
  const splitInitPrevDate = myDate
    .toISOString()
    .substring(0, 10)
    .split("-").reverse().join('.');

  return splitInitPrevDate
}

export default {
  components: {
    DateRangePicker,
    SearchFilterMultiSelect,
  },
  data() {
    return {
      minDate: null,
      maxDate: initPrevDate,
      dateSelectBoxStyle: { width: "300px", height: "unset" },
      dateRange: {
        start: viewDateHandler(initPrevDate),
        end: viewDateHandler(initPrevDate),
      },
      searchUserOrganisation: "",
      selectedUserOrganisation: [],
    }
  },
  created() {
    
  },
  computed: {
    ...mapGetters({
      userOrganisations: "user/userOrganisations",
    }),
    normalizeDateToAddInUrl() {
      const splitDateStart = this.dateRange.start?.split(".");
      const splitDateEnd = this.dateRange.end?.split(".");
      const result = {
        start: `${splitDateStart[2]}-${splitDateStart[1]}-${splitDateStart[0]}`,
        end: `${splitDateEnd[2]}-${splitDateEnd[1]}-${splitDateEnd[0]}`,
      };
      return result;
    },
    computedUserOrganisations () {
      let result = transformOrgsIntoOrgTree(this.userOrganisations)
      result = result.reduce((acc, item) => {
        acc.push(item)

        if (item.children?.length > 0) {
          for (const iterator of item.children) {
            acc.push({...iterator, itsChild: true})
          }
        }

        return acc
      }, [])
      
      return result
    },
    disabledDownloadButton() {
      return !(
        this.selectedUserOrganisation.length > 0 &&
        this.dateRange.start &&
        this.dateRange.end
      );
    },
  },
  methods: {
    ...mapMutations({
      setIsVisibleModalReportDelay: "online_monitoring/set_is_visible_modal_report_delay"
    }),
    downloadReport() {
      let url = new URL(`${process.env.VUE_APP_WN}/fact/delay_report`);
      url.searchParams.append("api_token", localStorage.getItem("auth_token"));
      url.searchParams.append("begin_date", this.normalizeDateToAddInUrl.start);
      url.searchParams.append("end_date", this.normalizeDateToAddInUrl.end);
      this.selectedUserOrganisation.forEach((element, index) => {
        url.searchParams.append(`organisation_ids[]`, element.id);
      });

      window.location.href = url
    },
    dateChange(event) {
      this.dateRange = event
    },
  },
}
</script>

<style lang="scss" scoped>

</style>